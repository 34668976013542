import axios from "axios";
import { API_URL } from "../config";

export const createAddress = (token, data) => axios.post(`${API_URL}/addresses`, data, {
    headers: {
        authorization: 'Bearer ' + token
    }
})

export const updateAddress = (token, id, data) => axios.put(`${API_URL}/addresses/${id}`, data, {
    headers: {
        authorization: 'Bearer ' + token
    }
})

export const deleteAddress = (token, id) => axios.delete(`${API_URL}/addresses/${id}`, {
    headers: {
        authorization: 'Bearer ' + token
    }
})
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, NavLink, Outlet, useParams } from "react-router-dom"
import OrderList from "../../../components/order-list"
import Section from "../../../components/section"
import { API_URL } from "../../../config"
import { setShopData } from "../../../features/shopSlice"
import { setData } from "../../../features/userSlice"
import { getShop } from "../../../services/shops"
import { getUser } from "../../../services/users"

const Shops = () => {
    const { id } = useParams()
    const { token, shop, user } = useSelector(state => state)

    const [loading, setLoading] = useState(true)

    const dispatch = useDispatch()

    useEffect(() => {
        Promise.all([
            getUser(token),
            getShop(token, id)
        ]).then(([user, shop]) => {
            dispatch(setData(user.data.payload))
            dispatch(setShopData(shop.data.payload))
            setLoading(false)
        })
    }, [dispatch, id, token])

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <Section>
            <section className="overflow-hidden rounded bg-white">
                {shop.logo &&
                    <img className="h-[200px]" src={`${API_URL}/uploads/shop_logos/${shop.logo}`} alt="logo" />
                }
                <section className="p-5">
                    <p className="text-xl font-semibold">{shop.name}</p>
                    <div className="flex flex-col gap-2">
                        <p className="text-sm">{shop.address.address}</p>
                        <p className="text-sm">{shop.shopCategory.name}</p>
                        <p className="text-sm">{shop.closed ? <span className="text-red-600">Cerrado</span> : <span className="text-green-600">Abierto</span>}</p>
                        <p className="text-sm">{shop.shopSections.length} secciones.</p>
                    </div>
                </section>
            </section>
            {
                user.id === shop.userId &&
                <Link
                    to={`/shops/${id}/orders`}
                    className="p-5 bg-white rounded text-gray-700 text-xs uppercase text-center">Ver ordenes de compra</Link>
            }
            {
                Object.values(shop.order).length > 0 &&
                <OrderList />
            }
            <section className="flex rounded overflow-hidden">
                {/* <NavLink to={`/shops/${id}`} className={({ isActive }) => `w-full text-center ${isActive ? 'bg-gray-100' : 'bg-white'} py-2 text-zinc-600 hover:bg-gray-100 font-semibold`} end>Principal</NavLink> */}
                <NavLink to={`/shops/${id}`} className={({ isActive }) => `w-full text-center ${isActive ? 'bg-gray-100' : 'bg-white'} py-2 text-zinc-600 hover:bg-gray-100 font-semibold`} end>Secciones</NavLink>
                <NavLink to={`/shops/${id}/products`} className={({ isActive }) => `w-full text-center ${isActive ? 'bg-gray-100' : 'bg-white'} py-2 text-zinc-600 hover:bg-gray-100 font-semibold`} end>Productos</NavLink>
            </section>
            <Outlet />
        </Section>
    )
}

export default Shops
import axios from "axios";
import { API_URL } from "../config";

export const getOrders = ({ token }) => axios.get(`${API_URL}/orders`, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const createOrder = ({ token, products, shopId, addressId, paymentMethod }) => axios.post(`${API_URL}/orders`, { products, addressId, shopId, paymentMethod }, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const changeReadyStatus = ({ token, orderId, ready }) => axios.patch(`${API_URL}/orders/${orderId}/ready`, { ready }, {
    headers: {
        authorization: `Bearer ${token}`
    }
})


export const changeDeliveryInProgressStatus = ({ token, orderId, deliveryInProgress }) => axios.patch(`${API_URL}/orders/${orderId}/delivery-in-progress`, { deliveryInProgress }, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const changeDeliveredStatus = ({ token, orderId, delivered }) => axios.patch(`${API_URL}/orders/${orderId}/delivered`, { delivered }, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const changePaidStatus = ({ token, orderId, paid }) => axios.patch(`${API_URL}/orders/${orderId}/paid`, { paid }, {
    headers: {
        authorization: `Bearer ${token}`
    }
})
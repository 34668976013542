import { useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { removeShopSection } from "../../features/shopSlice"
import { deleteSection, updateSectionName } from "../../services/sections"
import Button from "../button"
import Card from "../card"
import Dropdown from "../dropdown"
import Input from "../input"
import Label from "../label"
import ProductPreview from "../product/ProductPreview"

const ShopSection = ({ section: { id, name, products }, initialState = false }) => {
    const { id: shopId } = useParams()
    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const shop = useSelector(state => state.shop)
    const dispatch = useDispatch()

    const [sectionName, setSectionName] = useState(name)
    const [update, setUpdate] = useState(false)
    const [deleteAlert, setDeleteAlert] = useState(false)

    const handleDeleteAlert = () => {
        setDeleteAlert(!deleteAlert)
    }

    const handleDelete = () => {
        deleteSection({ token, sectionId: id, shopId })
        dispatch(removeShopSection({ sectionId: id }))
    }

    const handleChange = ({ target: { value } }) => setSectionName(value)

    const handleModeUpdate = () => setUpdate(true)

    const handleSubmit = (event) => {
        event.preventDefault()
        updateSectionName({ token, sectionId: id, shopId, sectionName })
            .then(() => {
                setUpdate(false)
                toast.success("Nombre actualizado")
            })
            .catch(({ response: res }) => toast.error(res.data.message))
    }

    if (update)
        return (
            <Card>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <Label text="Nombre de la sección *Obligatorio">
                        <Input type="text" placeholder="Nombre de la sección" value={sectionName} onChange={handleChange} />
                    </Label>
                    <Button>Actualizar</Button>
                </form>
            </Card>
        )

    return (
        <Dropdown name={sectionName} initialState={initialState}>
            <div className="flex flex-col gap-5">
                {products.length === 0 ?
                    <p>No hay productos</p>
                    :
                    <>
                        <div className="flex gap-2 overflow-x-auto">
                            {products.map(product => <ProductPreview key={product.id} product={product} />)}
                        </div>
                    </>
                }
                {user.id === shop.userId &&
                    <>
                        <div className="flex gap-5">
                            <Link className="text-blue-600 text-xs w-full px-5 py-2 text-center uppercase font-semibold bg-gray-50 rounded" onClick={handleModeUpdate}>Editar</Link>
                            <button className="text-red-600 text-xs w-full px-5 py-2 text-center uppercase font-semibold bg-gray-50 rounded" onClick={handleDeleteAlert}>{deleteAlert ? 'Cancelar' : 'Eliminar'}</button>
                        </div>
                        {deleteAlert && <div className="flex flex-col gap-2 p-5 border border-gray-200 rounded">
                            <p className="text-gray-600 text-center text-sm">Tené en cuenta que al eliminar una sección, todos los productos que contenga también lo harán.</p>
                            <p className="text-gray-600 text-center text-sm">¿Estás segurx de eliminar esta sección?</p>
                            <button className="px-5 py-2 bg-gray-50 rounded text-red-600 text-sm self-center" onClick={handleDelete}>Si, eliminar.</button>
                        </div>}
                    </>
                }
                <Link to={`/shops/${shopId}/sections/${id}/products`} className="text-zinc-700 text-sm font-semibold self-center">
                    Ver más
                </Link>
            </div>
        </Dropdown>

    )
}

export default ShopSection
import { RiCheckboxBlankCircleFill, RiLogoutBoxLine, RiShoppingCart2Line, RiTodoLine } from "react-icons/ri"
import { MdArrowBackIosNew } from "react-icons/md"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import theme from "../../../theme"
import Loader from "../../../components/Loader"
import { useDispatch, useSelector } from "react-redux"
import { getUserShop } from "../../../services/users"
import { deleteToken } from "../../../features/tokenSlice"
import ShopOrders from "./ShopOrders"
import Modal from "../../../components/Modal"
import lang from "../../../lang"
import { toggleOpen, toggleOrderDelivered, toggleOrderDeliveryInProgress, toggleOrderPaid, toggleOrderReady } from "../../../features/orderSlice"
import Button from "../../../components/button"
import { changeDeliveredStatus, changeDeliveryInProgressStatus, changePaidStatus, changeReadyStatus } from "../../../services/orders"

const NewHome = () => {
    const [shop, setShop] = useState({})
    const [shopNotFound, setShopNotFound] = useState(false)

    const { token, shopOrders, order } = useSelector(state => state)

    const [requesting, setRequesting] = useState(true)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChangeReadyStatus = () => {
        dispatch(toggleOrderReady())
        changeReadyStatus({ token, orderId: order.data.id, ready: !order.data.ready })
    }

    const handleChangeDeliveryInProgressStatus = () => {
        dispatch(toggleOrderDeliveryInProgress())
        changeDeliveryInProgressStatus({ token, orderId: order.data.id, deliveryInProgress: !order.data.deliveryInProgress })
    }

    const handleChangeDeliveredStatus = () => {
        dispatch(toggleOrderDelivered())
        changeDeliveredStatus({ token, orderId: order.data.id, delivered: !order.data.delivered })
    }
    const handleChangePaidStatus = () => {
        dispatch(toggleOrderPaid())
        changePaidStatus({ token, orderId: order.data.id, paid: !order.data.paid })
    }

    useEffect(() => {
        getUserShop({ token })
            .then(res => {
                setRequesting(false)
                setShop(res.data.payload.shop)
            })
            .catch(({ response: res }) => {
                setRequesting(false)
                if (res.status === 404) setShopNotFound(true)
            })
    }, [])

    if (requesting) {
        return <Loader />
    }

    return (
        <main className={`min-h-screen flex flex-col ${theme.bg} gap-5`}>
            <Modal modalOpen={order.open} set={() => dispatch(toggleOpen())}>
                <div className={`${theme.bg} flex flex-col gap-5 rounded w-full h-full overflow-auto cursor-auto`}>
                    <div className="flex items-center justify-between p-5">
                        <button className="text-white text-xl" onClick={() => dispatch(toggleOpen())}><MdArrowBackIosNew /></button>
                        <p className="text-white">Lista de productos</p>
                        <button></button>
                    </div>
                    <div className="bg-white rounded-t-3xl p-5 min-h-full max-h-full overflow-auto">
                        <div className="flex flex-col p-2 gap-2">
                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['client']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.data.customer.fullName}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">Número de telefono</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.data.customer.phoneNumber}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['quantity']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.data.order_products.reduce((acc, crr) => Number(acc) + Number(crr.quantity), 0)}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['payment_method']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.data.paymentMethod === 'CREDIT_CARD' ? lang['es']['credit_card'] : lang['es']['cash']}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['payment_state']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.data.paid ? lang['es']['paid'] : lang['es']['not_paid']}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['total']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.data.amount} {lang['currency']['abbreviation']}</p>
                            </div>
                            <hr />
                            <div className="flex flex-col gap-2 p-1 max-h-[325px]">
                                <p className="text-gray-500 upercase text-sm">{lang['es']['products']}</p>
                                {order.data.order_products.map(orderProduct =>
                                (
                                    <div key={`order_product_${orderProduct.id}`} className="flex items-center gap-5 px-2 py-1 rounded bg-gray-50" >
                                        <img className="min-w-[50px] w-[50px] min-h-[50px] h-[50px] rounded" src={`${orderProduct.product.image}`} alt={orderProduct.product.name} />

                                        <div className="flex flex-col gap-5">
                                            <p className="text-gray-500 text-sm">{orderProduct.product.name}</p>
                                            <div className="flex gap-1 items-center justify-between">
                                                <p className="text-xs text-zinc-600">{lang['es']['quantity']} {orderProduct.quantity}</p>
                                                <p className="text-xs text-zinc-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                                                <p className="text-xs text-zinc-600">P.U {orderProduct.price} {lang['currency']['abbreviation']}</p>
                                                <p className="text-xs text-zinc-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                                                <p className="text-xs text-zinc-600">{lang['es']['total']} {orderProduct.amount} {lang['currency']['abbreviation']}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                                )}
                            </div>


                            <hr />
                            <p className="text-gray-500 upercase text-sm">Estado</p>
                            <div className="flex flex-col">
                                {!order.data.ready &&
                                    <div className="border-l-2 border-gray-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-gray-200" /> Esperando confirmación del comercio
                                        </p>
                                    </div>
                                }

                                {order.data.ready &&
                                    <div className="border-l-2 border-green-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> El comercio ha preparado tu pedido
                                        </p>
                                    </div>
                                }

                                {order.data.ready && !order.data.deliveryInProgress &&
                                    <div className="border-l-2 border-gray-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-gray-200" /> Esperando repartidor
                                        </p>
                                    </div>
                                }

                                {order.data.ready && order.data.deliveryInProgress &&
                                    <div className="border-l-2 border-green-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> Repartidor asignado
                                        </p>
                                    </div>
                                }


                                {order.data.ready && order.data.deliveryInProgress && !order.data.delivered &&
                                    <div className="border-l-2 border-gray-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-gray-200" /> Repartidor en camino
                                        </p>
                                    </div>
                                }

                                {order.data.ready && order.data.deliveryInProgress && order.data.delivered &&
                                    <div className="border-l-2 border-green-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> Orden entregada
                                        </p>
                                    </div>
                                }
                            </div>

                            <hr />
                            <p className="text-gray-500 upercase text-sm">Cambiar estados</p>
                            {!order.data.ready && <Button onClick={handleChangeReadyStatus}>Preparado</Button>}
                            {order.data.ready && <Button onClick={handleChangeReadyStatus}>Pendiente</Button>}
                            {order.data.ready && !order.data.deliveryInProgress && <Button onClick={handleChangeDeliveryInProgressStatus}>Asignar repartidor</Button>}
                            {order.data.ready && order.data.deliveryInProgress && <Button onClick={handleChangeDeliveryInProgressStatus}>{lang['es']['unassign_deliveryman']}</Button>}
                            {order.data.ready && order.data.deliveryInProgress && !order.data.delivered && <Button onClick={handleChangeDeliveredStatus}>Entregar</Button>}
                            {order.data.ready && order.data.deliveryInProgress && order.data.delivered && <Button onClick={handleChangeDeliveredStatus}>No entregado</Button>}
                            {order.data.ready && order.data.deliveryInProgress && order.data.delivered && !order.data.paid && <Button onClick={handleChangePaidStatus}>Marcar como pagado</Button>}
                            {order.data.ready && order.data.deliveryInProgress && order.data.delivered && order.data.paid && <Button onClick={handleChangePaidStatus}>Marcar como no pagado</Button>}
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="flex items-center justify-between p-5">
                <button className="text-white text-xl" onClick={() => dispatch(deleteToken())}><RiLogoutBoxLine /></button>
                <p className="text-white">{shop?.name || 'Banasupro'}</p>
                <button className="flex gap-1 items-center bg-[#ffffff59] p-1 rounded-lg text-white">
                    <RiTodoLine className="text-xl" />
                    {shopOrders.reduce((prev, crr) => !crr.ready && !crr.deliveryInProgress && !crr.delivered ? prev + 1 : prev, 0)}
                </button>
            </div>
            <div className="flex flex-col flex-auto rounded-t-3xl h-full bg-white p-5">
                {shopNotFound ? <p className="text-zinc-500 text-center">Este usuario no tiene ninguna tienda asociada.</p> : <ShopOrders shopId={shop.id} />}
            </div>
        </main>

    )
}

export default NewHome
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import Address from "../../../components/address"
import Button from "../../../components/button"
import Card from "../../../components/card"
import Input from "../../../components/input"
import Section from "../../../components/section"
import { addAddress, setData } from "../../../features/userSlice"
import { createAddress } from "../../../services/addresses"
import { getUser } from "../../../services/users"

const Addresses = () => {
    const initState = {
        name: '',
        address: '',
        intersection: ''
    }

    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const [address, setAddress] = useState(initState)
    const [requesting, setRequesting] = useState(false)
    const dispacth = useDispatch()

    const handleChange = ({ target }) => setAddress({ ...address, [target.name]: target.value })

    const handleSubmit = (event) => {
        event.preventDefault()
        setRequesting(true)
        createAddress(token, address).then(res => {
            toast.success(res.data.message)
            dispacth(addAddress(res.data.payload))
            setAddress(initState)
            setRequesting(false)
        }).catch(({ response: res }) => {
            toast.error(res.data.message)
            setRequesting(false)
        })

    }
    useEffect(() => {
        getUser(token).then(user => dispacth(setData(user.data.payload)))
    }, [token, dispacth])

    return (
        <Section>
            <Card>
                <h1 className="text-zinc-600 text-lg">Dirección nueva</h1>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <label>Denominación <span className="text-xs">*Obligatorio</span></label>
                        <Input name="name" type="text" placeholder="Casa" value={address.name} onChange={handleChange} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Dirección <span className="text-xs">*Obligatorio</span></label>
                        <Input name="address" type="address" placeholder="Sargento Cabral 355" value={address.address} onChange={handleChange} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Intersección</label>
                        <Input name="intersection" type="text" placeholder="Bv belgrano" value={address.intersection} onChange={handleChange} />
                    </div>
                    <Button spinner={requesting} disabled={requesting}>Crear</Button>
                </form>
            </Card>
            <Card>
                <h1 className="text-lg text-zinc-600">Lista de direcciones</h1>
                {user.addresses.length === 0 ?
                    <p>Tu usuario no tiene direcciones creadas.</p>
                    :
                    user.addresses.map((address) => (
                        <Address key={address.id} address={address} showDate={true} showOptions={true} />
                    ))
                }
            </Card>
        </Section>
    )
}

export default Addresses
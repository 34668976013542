import { useState } from "react"
import toast, { Toaster } from "react-hot-toast"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { setToken } from "../../../features/tokenSlice"
import { login } from "../../../services/auth"
import Button from "../../../components/button"
import Input from "../../../components/input"
import theme from "../../../theme"
import { CLIENT_URL } from "../../../config"

const Login = () => {
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const [requesting, setRequesting] = useState(false)

    const dispatch = useDispatch()
    const handleChange = ({ target }) => setForm({ ...form, [target.name]: target.value })
    const handleSubmit = (event) => {
        event.preventDefault()
        setRequesting(true)
        login(form)
            .then((res) => {
                setRequesting(false)
                dispatch(setToken(res.data.payload.token))
            })
            .catch(({ response: res }) => {
                setRequesting(false)
                toast.error(res.data.message)
            })
    }
    return (
        <main className={`flex gap-10 min-h-screen bg-orange-50 bg-cover bg-center bg-no-repeat`} style={{ backgroundImage: `url(${CLIENT_URL}/shop.jpeg)` }}>
            <div className="flex flex-auto justify-center items-center bg-[#0000009f]">
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                />
                <div className="flex flex-col w-[350px] gap-5 bg-white rounded-3xl overflow-hidden">
                    <div className={`${theme.bg}`}>
                        <div className="flex h-full px-5 py-10">
                            <img className="mx-auto w-[120px]" src="/logo1_bg_white.png" alt="page-logo" />
                        </div>
                    </div>
                    <form className="flex flex-col gap-5 p-5" onSubmit={handleSubmit}>
                        <Input name="email" type="email" placeholder="Correo electrónico" onChange={handleChange} />
                        <Input name="password" type="password" placeholder="Contraseña" onChange={handleChange} />

                        <Button
                            spinner={requesting}
                            disabled={requesting}
                        >{!requesting && 'Inciar sesión'}</Button>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Login
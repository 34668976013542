import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressError from "../../../components/address-error";
import Card from "../../../components/card";
import Category from "../../../components/category";
import Section from "../../../components/section";
import Shop from "../../../components/shop";
import { setData } from "../../../features/userSlice";
import { getShopCategories } from "../../../services/categories";
import { searchShops } from "../../../services/shops";
import { getUser } from "../../../services/users";

function Home() {
    const [loading, setLoading] = useState(true)
    const [loadingShops, setLoadingShops] = useState(true)
    const [shopCategories, setShopCategories] = useState([])
    const [shops, setShops] = useState([])
    const [categoryId, setCategoryId] = useState(null)

    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const selectCategory = (categoryId) => {
        setCategoryId(categoryId)
        setLoadingShops(true)
        searchShops({ token, filter: { shopCategory: categoryId } }).then(res => {
            setShops(res.data.payload)
            setLoadingShops(false)
        })
    }

    useEffect(() => {
        Promise.all([
            getUser(token),
            getShopCategories(),
        ]).then(([user, shopCat]) => {
            dispatch(setData(user.data.payload))
            setShopCategories(shopCat.data.payload)
            setLoading(false)
            setCategoryId(shopCat.data.payload[0].id)
            searchShops({ token, filter: { shopCategory: shopCat.data.payload[0].id } }).then(res => {
                setShops(res.data.payload)
                setLoadingShops(false)
            })
        })
    }, [dispatch, token])

    if (loading) return <p>Loading...</p>

    return (
        <Section>
            <Card>
                <h1 className="text-lg text-zinc-600">Buscar</h1>
                <h1 className="uppercase text-zinc-600 font-semibold">Comercios</h1>
                {shopCategories.length > 0 ?
                    shopCategories.map(category => (
                        <Category key={category.id} category={category} selected={categoryId === category.id} onClick={() => selectCategory(category.id)} />
                    ))
                    :
                    <p className="text-zinc-600">No hay categorías de comercios creadas.</p>
                }
            </Card>

            {loadingShops ?
                <p>Loading...</p>
                :
                <Card>
                    {
                        shops.length > 0 ?
                            shops.map(shop => <Shop key={shop.id} shop={shop} />)
                            :
                            <p>No hay tiendas en esta categoría.</p>
                    }
                </Card>
            }

        </Section>

    );
}

export default Home

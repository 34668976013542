import { useState } from "react"
import toast, { Toaster } from "react-hot-toast"
import { Link } from "react-router-dom"
import { createUser } from "../../../services/users"
import Button from "../../../components/button"
import { CLIENT_URL } from "../../../config"
import theme from "../../../theme"
import Input from "../../../components/input"

const Register = () => {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    })
    const [requesting, setRequesting] = useState(false)

    const handleChange = ({ target }) => setForm({ ...form, [target.name]: target.value })
    const handleSubmit = (event) => {
        event.preventDefault()
        setRequesting(true)
        createUser(form).then(() => {
            toast.success("¡Te haz registrado exitosamente!")
            setRequesting(false)
        }).catch(error => {
            setRequesting(false)
            toast.error(error.response.data.message)
        })

    }

    return (
        <main className={`flex gap-10 min-h-screen bg-orange-50 bg-cover bg-center bg-no-repeat`} style={{ backgroundImage: `url(${CLIENT_URL}/shop.jpeg)` }}>
            <div className="flex flex-auto justify-center items-center bg-[#0000009f]">
                <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                />
                <div className="flex flex-col gap-5 bg-white rounded-3xl overflow-hidden w-[350px]">
                    <div className={`${theme.bg}`}>
                        <div className="flex h-full px-5 py-10">
                            <img className="mx-auto w-[120px]" src="/logo1_bg_white.png" alt="page-logo" />
                        </div>
                    </div>
                    <form className="flex flex-col gap-5 p-5" onSubmit={handleSubmit}>
                        <Input name="firstName" type="text" placeholder="Nombre" value={form.firstName} onChange={handleChange} />

                        <Input name="lastName" type="text" placeholder="Apellido" value={form.lastName} onChange={handleChange} />

                        <Input name="email" type="email" placeholder="Correo electrónico" value={form.email} onChange={handleChange} />

                        <Input name="password" type="password" placeholder="Contraseña" value={form.password} onChange={handleChange} />

                        <Button
                            spinner={requesting}
                            disabled={requesting}
                            className="bg-orange-500 h-[50px] rounded text-white uppercase text-sm font-semibold"
                        >{!requesting && 'Registrarse'}</Button>

                        <p className="text-zinc-600 text-center">¿Ya tienes cuenta? <Link to="/" className="font-semibold">Inicia sesión</Link></p>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Register
import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const shopOrdersSlice = createSlice({
    name: 'shopOrders',
    initialState,
    reducers: {
        setShopOrders: (state, { payload }) => payload,
        addShopOrder: (state, { payload }) => state.push(payload),
        deleteShopOrder: (state, { payload: orderId }) => {
            const newList = state.filter(order => order.id !== orderId)
            return newList
        },
        toggleOrderReady: (state, { payload: orderId }) => {
            state.map(order => {
                if (order.id === orderId) {
                    if (!order.ready) {
                        order.ready = !order.ready
                        order.deliveryInProgress = false
                        order.delivered = false
                        order.paid = false
                    } else {
                        order.ready = !order.ready
                    }
                }
                return order
            })
        },
        toggleOrderDeliveryInProgress: (state, { payload: orderId }) => {
            state.map(order => {
                if (order.id === orderId) {
                    order.deliveryInProgress = !order.deliveryInProgress
                }
                return order
            })
        },
        toggleOrderDelivered: (state, { payload: orderId }) => {
            state.map(order => {
                if (order.id === orderId) {
                    if (!order.delivered) {
                        order.delivered = !order.delivered
                        order.paid = true
                    } else {
                        order.delivered = !order.delivered
                        order.paid = false
                    }
                }
                return order
            })
        },
        toggleOrderPaid: (state, { payload: orderId }) => {
            state.map(order => {
                if (order.id === orderId) {
                    order.paid = !order.paid
                }
                return order
            })
        },
    }

})
export const {
    setShopOrders,
    addShopOrder,
    deleteShopOrder,
    toggleOrderReady,
    toggleOrderDeliveryInProgress,
    toggleOrderDelivered,
    toggleOrderPaid
} = shopOrdersSlice.actions
export default shopOrdersSlice.reducer
import { useEffect } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Order from "../../../components/order"
import Section from "../../../components/section"
import { setData } from "../../../features/userSlice"
import { getUser, getUserOrders } from "../../../services/users"

const Orders = () => {
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()

    const [orders, setOrders] = useState([])

    useEffect(() => {
        getUser(token).then(user => dispatch(setData(user.data.payload)))
        getUserOrders(token).then((res) => {
            setOrders(res.data.payload)
        })
    }, [token, dispatch])

    return (
        <Section className="flex flex-col gap-5">
            {orders.map((order) => <Order key={order.id} order={order} />)}
        </Section>
    )
}

export default Orders
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {
        customer: {
            fullName: '',
            phoneNumber: ''
        },
        order_products: []
    },
    open: false
}

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderData: (state, { payload: order }) => {
            state.data = order
        },
        toggleOpen: (state) => {
            state.open = !state.open
        },
        toggleOrderReady: (state) => {
            if (!state.data.ready) {
                state.data.ready = !state.data.ready
                state.data.deliveryInProgress = false
                state.data.delivered = false
                state.data.paid = false
            } else {
                state.data.ready = !state.data.ready
            }
        },
        toggleOrderDeliveryInProgress: (state) => {
            state.data.deliveryInProgress = !state.data.deliveryInProgress
        },
        toggleOrderDelivered: (state) => {
            if (!state.data.delivered) {
                state.data.delivered = !state.data.delivered
                state.data.paid = true
            } else {
                state.data.delivered = !state.data.delivered
                state.data.paid = false
            }
        },
        toggleOrderPaid: (state) => {
            state.data.paid = !state.data.paid
        },
        resetOrder: () => initialState
    }
})
export const {
    setOrderData,
    toggleOpen,
    resetOrder,
    toggleOrderReady,
    toggleOrderDeliveryInProgress,
    toggleOrderDelivered,
    toggleOrderPaid
} = orderSlice.actions
export default orderSlice.reducer
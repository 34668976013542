import { useState } from "react"
import lang from "../../lang"
import { deleteProduct } from "../../services/products"
import { useDispatch, useSelector } from "react-redux"
import { deleteShopProduct } from "../../features/shopSlice"

const DeleteProduct = ({ product }) => {
    const { token } = useSelector(state => state)
    const [deleting, setDeleting] = useState(false)
    const dispatch = useDispatch()

    const handleDeleteProduct = () => {
        setDeleting(true)
        deleteProduct({ token, productId: product.id }).then(() => {
            dispatch(deleteShopProduct({ id: product.id }))
        }).catch(() => {
            setDeleting(false)
        })
    }
    return (
        <div className="flex flex-col bg-white rounded w-[312px] overflow-hidden">
            <div className="p-5">
                <p className="text-gray-500 text-center">¿Deseas eliminar este producto?</p>
            </div>
            <button
                className="px-5 py-3 uppercase text-center text-sm hover:bg-gray-100 text-red-500"
                onClick={handleDeleteProduct}
                disabled={deleting}
            >
                {deleting ? lang['es']['deleting'] : lang['es']['delete']}
            </button>
        </div>
    )
}

export default DeleteProduct
import { useSelector } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom"

import NoAuthPages from "./pages/no-auth"
import Welcome from "./pages/no-auth/welcome"
import Register from "./pages/no-auth/register"
import Login from "./pages/no-auth/login"

import AppTemplate from "./pages/app"
import Home from "./pages/app/home"
import Profile from "./pages/app/profile"
import Addresses from "./pages/app/addresses"
import UpdateAddress from "./pages/app/addresses/update"
import Categories from "./pages/app/categories"
import CreateShop from "./pages/app/create-shop"
import MyShops from "./pages/app/my-shops"
// import UpdateShop from "./pages/app/shop/update"
import ErrorPage from "./pages/error"
import Shops from "./pages/app/shops"
import ShopSections from "./pages/app/shops/sections"
import ShopProducts from "./pages/app/shops/products"
import SectionProducts from "./pages/app/shops/sections/products"
import Orders from "./pages/app/orders"
import Deliveries from "./pages/app/deliveries"
import MyDeliveries from "./pages/app/my-deliveries"
import ShopOrders from "./pages/app/shops/Orders"
import NewHome from "./pages/app/NewHome"

const AuthNoRequired = ({ children }) => {
    const token = useSelector(state => state.token)
    const location = useLocation()

    if (token) {
        return <Navigate to="/home" state={{ from: location }} replace />
    }
    return children
}

const AuthRequired = ({ children }) => {
    const token = useSelector(state => state.token)
    const location = useLocation()

    if (!token) {
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return children
}

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<NoAuthPages />}>
                    {/* <Route path="" element={<Welcome />} /> */}
                    <Route path="" element={
                        <AuthNoRequired>
                            <Login />
                        </AuthNoRequired>
                    } />
                    <Route path="register" element={
                        <AuthNoRequired>
                            <Register />
                        </AuthNoRequired>
                    } />
                </Route>
                <Route element={<AuthRequired><AppTemplate /></AuthRequired>}>
                    <Route path="home" element={<NewHome />} />
                    <Route path="pending" element={<NewHome />} />

                    <Route path="user/data" element={<Profile />} />
                    <Route path="user/shops" element={<MyShops />} />
                    <Route path="user/addresses" element={<Addresses />} />

                    <Route path="address/:id" element={<UpdateAddress />} />
                    
                    <Route path="shop/create" element={<CreateShop />} />
                    
                    <Route path="shops/:id/orders" element={<ShopOrders />} />
                    <Route path="shops/:id" element={<Shops />}>
                        <Route index element={<ShopSections />} />
                        <Route path="sections/:sectionId/products" element={<SectionProducts />} />
                        <Route path="products" element={<ShopProducts />} />
                    </Route>

                    <Route path="orders" element={<Orders />} />
                    <Route path="deliveries" element={<Deliveries />} />
                    <Route path="deliveries/accepted" element={<MyDeliveries />} />

                    <Route path="categories" element={<Categories />} />
                </Route>
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter >
    )

}

export default App
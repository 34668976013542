import Card from "../../../components/card"
import Button from "../../../components/button"
import { useEffect, useState } from "react"
import { updateUserData } from "../../../services/users"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import Input from "../../../components/input"

const UserDataForm = ({ initData }) => {
    const [data, setUserData] = useState(initData)
    const [requesting, setRequesting] = useState(false)

    const { token } = useSelector(state => state)

    const handleUserChange = ({ target }) => setUserData({ ...data, [target.name]: target.value })

    const handleUpdateUser = (event) => {
        event.preventDefault()

        setRequesting(true)
        updateUserData(token, data).then(res => {
            setRequesting(false)
            toast.success(res.data.message)
        }).catch(({ response: res }) => {
            setRequesting(false)
            toast.error(res.data.message)
        })
    }

    useEffect(() => {
        setUserData(initData)
    }, [initData])

    return (
        <Card>
            <h1 className="text-zinc-600 text-lg">Datos de usuario</h1>
            <form className="flex flex-col gap-5" onSubmit={handleUpdateUser}>
                <div className="flex flex-col gap-2">
                    <label className="text-zinc-600">Nombre <span className="text-xs">*Obligatorio</span></label>
                    <Input name="firstName" type="text" placeholder="Juan" value={data.firstName} onChange={handleUserChange} />
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-zinc-600">Apellido <span className="text-xs">*Obligatorio</span></label>
                    <Input name="lastName" type="text" placeholder="Gauna" value={data.lastName} onChange={handleUserChange} />
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-zinc-600">Correo electrónico <span className="text-xs">*Obligatorio</span></label>
                    <Input name="email" type="email" placeholder="Banasupro@gmail.com" value={data.email} onChange={handleUserChange} />
                </div>
                <div className="flex flex-col gap-2">
                    <label className="text-zinc-600">Contraseña</label>
                    <Input type="password" placeholder="******************" disabled={true} />
                </div>
                <Button spinner={requesting} disabled={requesting}>Actualizar</Button>
            </form>
        </Card>
    )
}

export default UserDataForm
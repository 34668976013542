const ProductList = ({ productList }) => {
    return (
        <div className="border rounded px-5 py-2">
            <p className="text-zinc-600">Lista</p>
            {
                productList.map(({ id, quantity, product, price }) => (
                    <div key={id}>
                        <p className="text-sm text-zinc-600 font-semibold">{product.name}</p>
                        <p>
                            <span className="text-green-500">${price}</span><span className="text-sm text-zinc-600">x{quantity}</span>
                        </p>
                    </div>
                ))
            }
        </div>
    )
}

export default ProductList
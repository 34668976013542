import { useEffect, useState } from "react"
import { getPendingOrders } from "../../../services/shops"
import { useDispatch, useSelector } from "react-redux"
import { setShopOrders } from "../../../features/shopOrdersSlice"
import PendingOrder from "../shops/Orders/PendingOrder"
import { button } from "react-router-dom"
import { setOrderData } from "../../../features/orderSlice"

const ShopOrders = ({ shopId }) => {
    const { token, shopOrders, order } = useSelector(state => state)

    const [pendings, setPendings] = useState([])
    const [ready, setReady] = useState([])
    const [inProgress, setInProgress] = useState([])
    const [completed, setCompleted] = useState([])

    const [section, setSection] = useState('PENDING')

    const dispatch = useDispatch()
    useEffect(() => {
        getPendingOrders({ token, shopId }).then((res) => {
            dispatch(setShopOrders(res.data.payload.orders))
        })
        const timer = !order.open && setInterval(() => {
            getPendingOrders({ token, shopId }).then((res) => {
                dispatch(setShopOrders(res.data.payload.orders))
            })
        }, 10000)

        return () => clearInterval(timer)
    }, [order])

    useEffect(() => {
        setPendings(shopOrders.filter(order => !order.ready && !order.deliveryInProgress && !order.delivered)) // pendientes
        setReady(shopOrders.filter(order => order.ready && !order.deliveryInProgress && !order.delivered)) // Preparadas
        setInProgress(shopOrders.filter(order => order.ready && order.deliveryInProgress && !order.delivered)) // En camino
        setCompleted(shopOrders.filter(order => order.ready && order.deliveryInProgress && order.delivered)) // Completadas
    }, [shopOrders])

    return (
        <section className="flex flex-col gap-5">
            <section className="grid grid-cols-2 gap-2 justify-center flex-wrap">
                <button
                    className={`text-center ${section === 'PENDING' ? 'bg-orange-50 text-orange-300' : 'text-zinc-400'} px-3 py-2 rounded-full`}
                    onClick={() => setSection('PENDING')}
                >
                    {pendings.length} Pendientes
                </button>
                <button
                    className={`text-center ${section === 'READY' ? 'bg-orange-50 text-orange-300' : 'text-zinc-400'} px-3 py-2 rounded-full`}
                    onClick={() => setSection('READY')}
                >
                    {ready.length} Preparadas
                </button>
                <button
                    className={`text-center ${section === 'IN_PROGRESS' ? 'bg-orange-50 text-orange-300' : 'text-zinc-400'} px-3 py-2 rounded-full`}
                    onClick={() => setSection('IN_PROGRESS')}
                >
                    {inProgress.length} En camino
                </button>
                <button
                    className={`text-center ${section === 'COMPLETED' ? 'bg-orange-50 text-orange-300' : 'text-zinc-400'} px-3 py-2 rounded-full`}
                    onClick={() => setSection('COMPLETED')}
                >
                    {completed.length} Completadas
                </button>
            </section>

            {section === 'PENDING' ?
                <>
                    {pendings.length > 0 ?
                        pendings.map(order => <PendingOrder key={order.id} order={order} />)
                        : <p className="text-zinc-500 text-center">No tienes ordenes aún.</p>
                    }
                </>
                : section === 'READY' ?
                    <>
                        {ready.length > 0 ?
                            ready.map(order => <PendingOrder key={order.id} order={order} />)
                            : <p className="text-zinc-500 text-center">No tienes ordenes aún.</p>
                        }
                    </>
                    : section === 'IN_PROGRESS' ?
                        <>
                            {inProgress.length > 0 ?
                                inProgress.map(order => <PendingOrder key={order.id} order={order} />)
                                : <p className="text-zinc-500 text-center">No tienes ordenes aún.</p>
                            }
                        </>
                        :
                        <>
                            {completed.length > 0 ?
                                completed.map(order => <PendingOrder key={order.id} order={order} />)
                                : <p className="text-zinc-500 text-center">No tienes ordenes aún.</p>
                            }
                        </>
            }
        </section>
    )
}

export default ShopOrders
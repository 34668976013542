import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Product from "../../../../../components/product"
import { getSection } from "../../../../../services/sections"
import Card from "../../../../../components/card"
import { setData } from "../../../../../features/userSlice"
import { getUser } from "../../../../../services/users"

const SectionProducts = () => {
    const { sectionId } = useParams()
    const [section, setSectionData] = useState()
    const [loading, setLoading] = useState(true)

    const token = useSelector(state => state.token)

    const dispatch = useDispatch()

    useEffect(() => {
        Promise.all([
            getUser(token),
            getSection({ token, sectionId })
        ]).then(([user, section]) => {
            dispatch(setData(user.data.payload))
            setSectionData(section.data.payload)

            setLoading(false)
        })
    }, [setSectionData, dispatch, token, sectionId])

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <section className="flex flex-col gap-5">
            <Card>
                <p className="text-lg font-semibold text-zinc-600">{section.name}</p>
            </Card>
            {
                section.products.length > 0 ?
                    section.products.map(product => (
                        <Product key={product.id} product={product} />
                    ))
                    : <Card>No hay productos creados.</Card>
            }
        </section>
    )
}

export default SectionProducts
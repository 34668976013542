import Section from "../../../components/section"
import Card from "../../../components/card"
import Input from "../../../components/input"
import Button from "../../../components/button"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { setData } from "../../../features/userSlice"
import toast from "react-hot-toast"
import { getUser, updateUserData, updateUserNumberPhone } from "../../../services/users"
import PhoneNumberForm from "./PhoneNumberForm"
import UserDataForm from "./UserDataForm"

const Profile = () => {
    const token = useSelector(state => state.token)
    const [data, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: ''
    })
    const [numberPhone, setNumberPhone] = useState({
        areaCode: '',
        number: ''
    })
    const dispatch = useDispatch()


    useEffect(() => {
        getUser(token).then(user => {
            dispatch(setData(user.data.payload))


            console.log(user.data.payload)
            setUserData({
                firstName: user.data.payload.firstName,
                lastName: user.data.payload.lastName,
                email: user.data.payload.email
            })

            setNumberPhone({
                areaCode: user.data.payload.numberPhone.areaCode,
                number: user.data.payload.numberPhone.number
            })
        })

    }, [dispatch, token])

    return (
        <Section>
            <UserDataForm initData={data} />
            <PhoneNumberForm initData={numberPhone} />
        </Section>
    )
}

export default Profile
const ProductPreview = ({ product: { name, price } }) => {
    return (
        <div className="flex flex-col gap-1 min-w-[60%] p-2 bg-white border border-gray-100 rounded overflow-hidden">
            <p className="text-zinc-700 text-sm font-semibold">
                {name}
            </p>
            <p className="text-green-500">${price}</p>
        </div>
    )
}

export default ProductPreview
import { combineReducers } from "@reduxjs/toolkit"
import userReducer from '../features/userSlice'
import tokenReducer from '../features/tokenSlice'
import shopsReducers from "../features/shopsSlice"
import shopReducer from "../features/shopSlice"
import shopOrdersSlice from "../features/shopOrdersSlice"
import orderSlice from "../features/orderSlice"

const reducers = combineReducers({
    user: userReducer,
    token: tokenReducer,
    shops: shopsReducers,
    shop: shopReducer,
    shopOrders: shopOrdersSlice,
    order: orderSlice,
})

export default reducers
import { useState } from "react"
import { Toaster } from "react-hot-toast"
import { Outlet } from "react-router-dom"
import Navigation from "../../components/navigation"
const AppTemplate = () => {

    useState(() => {
        document.body.style.overflow = 'auto'
    }, [])

    return (
        <main className="relative flex flex-col gap-5 min-h-screen bg-[#e2e2e2]">
            <Toaster
                position="bottom-center"
                reverseOrder={false}
            />
            {/* <Navigation /> */}
            <Outlet />
        </main>
    )
}

export default AppTemplate
const Selectable = (
    {
        selected,
        onClick,
        children
    }
) => {
    return (
        <div
            className={`flex flex-col gap-2 px-5 py-3 border ${selected ? 'bg-gray-100 border-gray-300' : 'bg-white border-gray-100'} rounded transition-all duration-100`}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default Selectable
import { useState } from "react"
import { toast } from "react-hot-toast"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"
import { useSelector } from "react-redux"
import { acceptDelivery, cancelDelivery, decrementStatus, incrementStatus } from "../../services/deliveries"
import Address from "../address"
import Button from "../button"
import Dropdown from "../dropdown"
import Input from "../input"
import OrderSummary from "../order/orderSummary"
import ProductList from "../order/productList"
import TextArea from "../textarea"

const states = {
    0: 'Esperando repartidor',
    1: 'Repartidor encontrado',
    2: 'Repartidor en el local',
    3: 'Repartidor en camino',
    4: 'Repartidor ha llegado',
    5: 'Entregado',
    6: 'Cancelado',
}

const Delivery = ({ delivery, showCancelButton, showChangeStatusButton }) => {
    const [status, setStatus] = useState(delivery.status)
    const [reason, setReason] = useState("")
    const token = useSelector(state => state.token)
    const accept = () => {
        acceptDelivery({ token, deliveryId: delivery.id })
            .then(res => toast.success(res.data.message))
            .catch(({ response: res }) => toast.error(res.data.message))
    }

    const handleDrecrementStatus = () => {
        if (status - 1 >= 1) {
            setStatus(status - 1)
            decrementStatus({ token, deliveryId: delivery.id }).then(() => toast.success("Estado actualizado"))
        }
    }

    const handleIncrementStatus = () => {
        if (status + 1 <= 5) {
            setStatus(status + 1)
            incrementStatus({ token, deliveryId: delivery.id }).then(() => toast.success("Estado actualizado"))
        }
    }

    const handleCancelDelivery = () => {
        if (status < 3) {
            cancelDelivery({ token, deliveryId: delivery.id, cancellationReason: reason })
            setStatus(6)
        }
    }

    const handleChange = ({ target: { value } }) => setReason(value)

    return (
        <Dropdown name={`${delivery.order.shop.name} #${delivery.order.id}`}>
            <div className="flex justify-between">
                <p className="text-zinc-600">Cliente</p>
                <p className="font-semibold capitalize">{delivery.order.user.firstName} {delivery.order.user.lastName}</p>
            </div>
            <div className="flex justify-between">
                <p className="text-zinc-600">Télefono</p>
                <p className="font-semibold capitalize">{delivery.order.user.numberPhone.areaCode}-{delivery.order.user.numberPhone.number}</p>
            </div>
            <ProductList productList={delivery.order.order_products} />
            <p className="text-zinc-600">Dirección del cliente</p>
            <Address address={delivery.order.address} showName={false} />
            <p className="text-zinc-600">Dirección de la tienda</p>
            <Address address={delivery.order.shop.address} showName={false} />

            <OrderSummary order={delivery.order} />

            {!delivery.order.delivery.deliveryman && <Button onClick={accept}>Aceptar pedido</Button>}

            {showChangeStatusButton &&
                <>
                    <p className="text-zinc-600">Estado</p>
                    <div className="flex">
                        {status > 1 && status < 5 && <button className="p-2" onClick={handleDrecrementStatus}>
                            <RiArrowLeftSLine />
                        </button>}
                        <Input className="w-full text-center" value={states[status]} disabled />
                        {status < 5 && <button className="p-2" onClick={handleIncrementStatus}>
                            <RiArrowRightSLine />
                        </button>}
                    </div>
                </>

            }

            {status === 6 &&
                <>
                    <div className="px-5 py-2 border rounded">
                        <p className="text-zinc-600">Razón de la cancelación</p>
                        <p className="text-zinc-600">{delivery.cancellationReason || reason}</p>
                    </div>
                </>
            }

            {showCancelButton && status < 3 &&
                <div className="flex flex-col gap-2">
                    <p className="text-zinc-600">Cancelar pedido</p>
                    <TextArea placeholder="Escribe la razón" value={reason} onChange={handleChange}></TextArea>
                    <button className="p-2 rounded bg-red-700 text-white uppercase text-sm" onClick={handleCancelDelivery}>Cancelar</button>
                </div>
            }
        </Dropdown>
    )
}

export default Delivery
import axios from "axios";
import { API_URL } from "../config";

export const createProduct = ({ token, product }) => axios.post(`${API_URL}/products`, product, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const editProductDetails = ({ token, product }) => axios.patch(`${API_URL}/products/${product.id}/edit/details`, product, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const changeSoldOutStatus = ({ token, productId, soldOut }) => axios.patch(`${API_URL}/products/${productId}/edit/sold-out`, { soldOut }, {
    headers: {
        authorization: `Bearer ${token}`
    }
})

export const deleteProduct = ({ token, productId }) => axios.post(`${API_URL}/products/${productId}`, {}, {
    headers: {
        authorization: `Bearer ${token}`
    }
})
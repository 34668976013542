import { RiCheckboxBlankCircleFill } from "react-icons/ri"
import Address from "../address"
import Card from "../card"
import Dropdown from "../dropdown"

/*
Estados delivery
[0] Esperando repartidor
[1] Repartidor encontrado
[2] Repartidor en el local
[3] Repartidor en camino
[4] El repartidor ha llegado
[5] Entregado
[6] Cancelado
*/

const states = {
    0: 'Esperando repartidor',
    1: 'Repartidor encontrado',
    2: 'Repartidor en el local',
    3: 'Repartidor en camino',
    4: 'Repartidor ha llegado',
    5: 'Entregado',
    6: 'Cancelado',
}

const Order = (
    {
        order: {
            id,
            order_products,
            address,
            amount,
            shippingPrice
        }
    }
) => {
    return (
        <Card>
            <div className="flex gap-5 items-center">
                <div className="bg-gray-200 w-[50px] h-[50px] rounded">
                </div>
                <div className="flex flex-col">
                    {/* <p className="text-md text-zinc-900 font-semibold">{name}</p> */}
                    <div className="flex gap-1 items-center">
                        <p className="text-xs text-zinc-600">${amount}</p>
                        <p className="text-xs text-zinc-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                        <p className="text-xs text-zinc-600">{order_products.length} {order_products.length > 1 ? 'Productos' : 'Producto'}</p>
                    </div>
                </div>
            </div>
        </Card>
        // <Dropdown name={`${name} #${id}`}>
        //     <p className="text-zinc-600 font-semibold">{states[status]}</p>
        //     {deliveryman &&
        //         <div className="flex justify-between">
        //             <p className="text-zinc-600">Repartidor</p>
        //             <span className="text-zinc-600 capitalize font-semibold">{deliveryman.user.firstName} {deliveryman.user.lastName}</span>
        //         </div>
        //     }
        //     {status === 6 && <>
        //         <div className="flex flex-col gap-2 px-5 py-2 border rounded">
        //             <p className="text-zinc-600">Razón de la cancelación</p>
        //             <p className="text-zinc-600">{cancellationReason}</p>
        //         </div>
        //     </>}
        //     <div className="border rounded px-5 py-2">
        //         <p className="text-zinc-600">Lista</p>
        //         {
        //             order_products.map(({ id, quantity, product, price }) => (
        //                 <div key={id}>
        //                     <p className="text-sm text-zinc-600 font-semibold">{product.name}</p>
        //                     <p>
        //                         <span className="text-green-500">${price}</span><span className="text-sm text-zinc-600">x{quantity}</span>
        //                     </p>
        //                 </div>
        //             ))
        //         }
        //     </div>

        //     <div className="border rounded px-5 py-2">
        //         <div className="flex justify-between">
        //             <p className="text-zinc-600">Subtotal</p>
        //             <p className="text-zinc-600">${amount}</p>
        //         </div>
        //         <div className="flex justify-between">
        //             <p className="text-zinc-600">Envío</p>
        //             <p className="text-zinc-600">${shippingPrice}</p>
        //         </div>
        //         <div className="flex justify-between font-semibold">
        //             <p className="text-zinc-600">Total</p>
        //             <p className="text-zinc-600">${amount + shippingPrice}</p>
        //         </div>
        //     </div>
        //     <Address address={address} />
        // </Dropdown>
    )
}

export default Order
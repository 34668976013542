const OrderSummary = ({ order: { amount, shippingPrice} }) => {
    return (
        <div className="border rounded px-5 py-2">
            <div className="flex justify-between">
                <p className="text-zinc-600">Subtotal</p>
                <p className="text-zinc-600">${amount}</p>
            </div>
            <div className="flex justify-between">
                <p className="text-zinc-600">Envío</p>
                <p className="text-zinc-600">${shippingPrice}</p>
            </div>
            <div className="flex justify-between font-semibold">
                <p className="text-zinc-600">Total</p>
                <p className="text-zinc-600">${amount + shippingPrice}</p>
            </div>
        </div>
    )
}

export default OrderSummary
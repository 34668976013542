import { RiCheckboxBlankCircleFill, RiTodoLine } from "react-icons/ri"
import Modal from "../../../../components/Modal"
import { HiLocationMarker } from "react-icons/hi"
import { MdArrowBackIosNew, MdOutlineExpandMore } from 'react-icons/md'
import { useEffect, useRef, useState } from "react"
import Button from "../../../../components/button"
import lang from "../../../../lang"
import { useDispatch, useSelector } from "react-redux"
import { toggleOrderDelivered, toggleOrderDeliveryInProgress, toggleOrderPaid, toggleOrderReady } from "../../../../features/shopOrdersSlice"
import { changeDeliveredStatus, changeDeliveryInProgressStatus, changePaidStatus, changeReadyStatus } from "../../../../services/orders"
import theme from "../../../../theme"
import { setOrderData, toggleOpen } from "../../../../features/orderSlice"

const PendingOrder = ({ order }) => {
    const orderRef = useRef()
    const [orderMenu, setOrderMenu] = useState(false)

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', };

    const { token } = useSelector(state => state)
    const disptach = useDispatch()

    const handleChangeReadyStatus = () => {
        disptach(toggleOrderReady(order.id))
        changeReadyStatus({ token, orderId: order.id, ready: !order.ready })
    }

    const handleChangeDeliveryInProgressStatus = () => {
        disptach(toggleOrderDeliveryInProgress(order.id))
        changeDeliveryInProgressStatus({ token, orderId: order.id, deliveryInProgress: !order.deliveryInProgress })
    }

    const handleChangeDeliveredStatus = () => {
        disptach(toggleOrderDelivered(order.id))
        changeDeliveredStatus({ token, orderId: order.id, delivered: !order.delivered })
    }
    const handleChangePaidStatus = () => {
        disptach(toggleOrderPaid(order.id))
        changePaidStatus({ token, orderId: order.id, paid: !order.paid })
    }
    return (
        <>
            <Modal modalOpen={orderMenu} set={setOrderMenu}>
                <div className={`${theme.bg} flex flex-col gap-5 rounded w-full h-full overflow-auto cursor-auto`}>
                    <div className="flex items-center justify-between p-5">
                        <button className="text-white text-xl" onClick={() => setOrderMenu(false)}><MdArrowBackIosNew /></button>
                        <p className="text-white">Lista de productos</p>
                        <button></button>
                    </div>
                    <div className="bg-white rounded-t-3xl p-5 min-h-full max-h-full overflow-auto">
                        <div className="flex flex-col p-2 gap-2">
                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['client']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.customer.fullName}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">Número de telefono</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.customer.phoneNumber}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['quantity']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.order_products.reduce((acc, crr) => Number(acc) + Number(crr.quantity), 0)}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['payment_method']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.paymentMethod === 'CREDIT_CARD' ? lang['es']['credit_card'] : lang['es']['cash']}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['payment_state']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.paid ? lang['es']['paid'] : lang['es']['not_paid']}</p>
                            </div>

                            <div className="flex justify-between">
                                <p className="text-gray-500 text-center text-sm uppercase">{lang['es']['total']}</p>
                                <p className="text-gray-500 text-center text-sm uppercase">{order.amount} {lang['currency']['abbreviation']}</p>
                            </div>
                            <hr />
                            <div className="flex flex-col gap-2 p-1 max-h-[325px]">
                                <p className="text-gray-500 upercase text-sm">{lang['es']['products']}</p>
                                {order.order_products.map(orderProduct =>
                                (
                                    <div key={`order_product_${orderProduct.id}`} className="flex items-center gap-5 px-2 py-1 rounded bg-gray-50" >
                                        <img className="min-w-[50px] w-[50px] min-h-[50px] h-[50px] rounded" src={`https://banasupro.softwaresolutionshn.com/${orderProduct.product.image}`} alt={orderProduct.product.name} />

                                        <div className="flex flex-col gap-5">
                                            <p className="text-gray-500 text-sm">{orderProduct.product.name}</p>
                                            <div className="flex gap-1 items-center justify-between">
                                                <p className="text-xs text-zinc-600">{lang['es']['quantity']} {orderProduct.quantity}</p>
                                                <p className="text-xs text-zinc-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                                                <p className="text-xs text-zinc-600">P.U {orderProduct.price} {lang['currency']['abbreviation']}</p>
                                                <p className="text-xs text-zinc-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                                                <p className="text-xs text-zinc-600">{lang['es']['total']} {orderProduct.amount} {lang['currency']['abbreviation']}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                                )}
                            </div>


                            <hr />
                            <p className="text-gray-500 upercase text-sm">Estado</p>
                            <div className="flex flex-col">
                                {!order.ready &&
                                    <div className="border-l-2 border-gray-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-gray-200" /> Esperando confirmación del comercio
                                        </p>
                                    </div>
                                }

                                {order.ready &&
                                    <div className="border-l-2 border-green-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> El comercio ha preparado tu pedido
                                        </p>
                                    </div>
                                }

                                {order.ready && !order.deliveryInProgress &&
                                    <div className="border-l-2 border-gray-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-gray-200" /> Esperando repartidor
                                        </p>
                                    </div>
                                }

                                {order.ready && order.deliveryInProgress &&
                                    <div className="border-l-2 border-green-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> Repartidor asignado
                                        </p>
                                    </div>
                                }


                                {order.ready && order.deliveryInProgress && !order.delivered &&
                                    <div className="border-l-2 border-gray-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-gray-200" /> Repartidor en camino
                                        </p>
                                    </div>
                                }

                                {order.ready && order.deliveryInProgress && order.delivered &&
                                    <div className="border-l-2 border-green-200 py-1">
                                        <p className="-ml-[4.5px] flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                                            <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> Orden entregada
                                        </p>
                                    </div>
                                }
                            </div>

                            <hr />
                            <p className="text-gray-500 upercase text-sm">Cambiar estados</p>
                            {!order.ready && <Button onClick={handleChangeReadyStatus}>Preparado</Button>}
                            {order.ready && <Button onClick={handleChangeReadyStatus}>Pendiente</Button>}
                            {order.ready && !order.deliveryInProgress && <Button onClick={handleChangeDeliveryInProgressStatus}>Asignar repartidor</Button>}
                            {order.ready && order.deliveryInProgress && <Button onClick={handleChangeDeliveryInProgressStatus}>{lang['es']['unassign_deliveryman']}</Button>}
                            {order.ready && order.deliveryInProgress && !order.delivered && <Button onClick={handleChangeDeliveredStatus}>Entregar</Button>}
                            {order.ready && order.deliveryInProgress && order.delivered && <Button onClick={handleChangeDeliveredStatus}>No entregado</Button>}
                            {order.ready && order.deliveryInProgress && order.delivered && !order.paid && <Button onClick={handleChangePaidStatus}>Marcar como pagado</Button>}
                            {order.ready && order.deliveryInProgress && order.delivered && order.paid && <Button onClick={handleChangePaidStatus}>Marcar como no pagado</Button>}
                        </div>
                    </div>
                </div>
            </Modal>

            <div ref={orderRef} className="flex flex-col gap-2 p-5 rounded-3xl bg-gray-50 select-none cursor-pointer" onClick={() => {
                disptach(toggleOpen())
                disptach(setOrderData(order))
            }}>
                <p className="text-orange-400 uppercase text-sm">Orden nro. {order.id}</p>
                <div className="flex gap-2 items-center">
                    <p className="text-xs text-orange-400">{order.amount} {lang['currency']['abbreviation']}</p>
                    <p className="text-xs text-orange-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                    <p className="text-xs text-orange-400">{order.order_products.reduce((acc, crr) => Number(acc) + Number(crr.quantity), 0)} {order.order_products.reduce((acc, crr) => Number(acc) + Number(crr.quantity), 0) > 1 ? 'Productos' : 'Producto'}</p>
                    {/* <p className="text-xs text-orange-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p> */}
                </div>
                {/* <div className="flex gap-2 items-center">
                    <p className="text-xs text-orange-400">{order.ready && order.deliveryInProgress && order.delivered ? 'Entregado' : 'Pendiente de entrega'}</p>
                    <p className="text-xs text-orange-500"><RiCheckboxBlankCircleFill className="text-[5px]" /></p>
                    <p className="text-xs text-orange-400">{order.paid ? 'Pagado' : 'No pagado'}</p>
                </div> */}
                <p className="flex items-center gap-1 text-xs text-orange-500"><HiLocationMarker /> {order.address}</p>
                <p className="flex items-center gap-1 text-xs text-orange-500">{new Date(order.createdAt).toLocaleDateString("es-HN", options)}. {new Date(order.createdAt).getHours()}:{new Date(order.createdAt).getMinutes()}</p>
                {/* {order.ready && order.deliveryInProgress && order.delivered &&
                    <p className="flex items-center gap-1 text-xs text-zinc-500 font-semibold">
                        <RiCheckboxBlankCircleFill className="text-[8px] text-green-500" /> Orden entregada
                    </p>
                } */}
            </div>
        </>

    )
}

export default PendingOrder
import Card from "../../../components/card"
import Button from "../../../components/button"
import { useEffect, useState } from "react"
import { updateUserNumberPhone } from "../../../services/users"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import Input from "../../../components/input"

const PhoneNumberForm = ({ initData }) => {
    const { token } = useSelector(state => state)
    const [numberPhone, setNumberPhone] = useState(initData)
    const [requesting, setRequesting] = useState()

    const handleUpdateNumberPhone = (event) => {
        event.preventDefault()

        setRequesting(true)
        updateUserNumberPhone(token, numberPhone).then(res => {
            toast.success(res.data.message)
            setRequesting(false)
        }).catch(({ response: res }) => {
            setRequesting(false)
            toast.error(res.data.message)
        })
    }

    const handleNPChange = ({ target }) => setNumberPhone({ ...numberPhone, [target.name]: target.value })

    useEffect(() => {
        setNumberPhone(initData)
    }, [initData])

    return (
        <Card>
            <h1 className="text-zinc-600 text-lg">Número de teléfono</h1>
            <form id="number-phone-form" className="flex flex-col gap-5" onSubmit={handleUpdateNumberPhone}>
                <div className="flex gap-2">
                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Caracteristica <span className="text-xs">*Obligatorio</span></label>
                        <Input className="w-full" name="areaCode" placeholder="3483" value={numberPhone.areaCode} onChange={handleNPChange} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-zinc-600">Número <span className="text-xs">*Obligatorio</span></label>
                        <Input className="w-full" name="number" placeholder="403020" value={numberPhone.number} onChange={handleNPChange} />
                    </div>
                </div>
                <Button spinner={requesting} disabled={requesting}>{!requesting && 'Actualizar'}</Button>
            </form>
        </Card>
    )
}

export default PhoneNumberForm
import { useState } from "react"
import { toast } from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { resetOrder } from "../../features/shopSlice"
import { createOrder } from "../../services/orders"
import Address from "../address"
import Dropdown from "../dropdown"

const OrderList = () => {
    const { id: shopId } = useParams()
    const [addressId, setAddressId] = useState(null)

    const token = useSelector(state => state.token)
    const shop = useSelector(state => state.shop)
    const user = useSelector(state => state.user)

    const dispatch = useDispatch()

    const selectAddress = (addrId) => {
        setAddressId(addrId !== addressId ? addrId : null)
    }

    const sendOrder = () => {
        const order = Object.values(shop.order).map(item => {
            return {
                quantity: item.quantity,
                productId: item.product.id
            }
        })
        createOrder({ token, products: order, addressId, shopId })
            .then((res) => {
                dispatch(resetOrder())
                toast.success(res.data.message)
            })
            .catch(({ response: res }) => {
                toast.error(res.data.message)
            })
    }

    return (
        <Dropdown name="Orden">
            <p className="font-semibold">Productos</p>
            {Object.values(shop.order).map(item => (
                <div key={item.product.id}>
                    <p className="text-sm text-zinc-600 font-semibold">{item.product.name}</p>
                    <p>
                        <span className="text-green-500">${item.product.price}</span><span className="text-sm text-zinc-600">x{item.quantity}</span>
                    </p>
                </div>
            ))}
            <p className="font-semibold">Dirección</p>
            <div className="flex flex-col gap-2">
                {user.addresses.map((address) => <Address key={address.id} address={address} selected={address.id === addressId} onClick={() => selectAddress(address.id)} />)}
            </div>
            {addressId && <button className="border border-orange-600 text-xs uppercase py-2 px-5 text-orange-600 rounded font-semibold" onClick={sendOrder}>Enviar pedido</button>}
        </Dropdown>
    )
}

export default OrderList
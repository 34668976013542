import TextArea from "../textarea"
import Button from "../button"
import { useState } from "react"
import Input from "../input"
import { editProductDetails } from "../../services/products"
import { useDispatch, useSelector } from "react-redux"
import { editProduct } from "../../features/shopSlice"
import lang from "../../lang"

const EditProductForm = ({
    product: { id, name, description, price },
    noActionCallback = () => { },
    success = () => { },
}) => {
    const { token } = useSelector(state => state)
    const [productFormData, setProductFormData] = useState({
        name,
        description,
        price
    })
    const [requesting, setRequesting] = useState(false)
    const dispatch = useDispatch()

    const handleEditProduct = (event) => {
        event.preventDefault()
        if (
            (productFormData.name.length === 0 && productFormData.description.length === 0 && productFormData.price.length === 0)
            ||
            (productFormData.name.length === 0 || productFormData.price.length === 0)
        ) return

        if (
            (productFormData.name === name && productFormData.description === description && productFormData.price === price)
        ) {
            noActionCallback()
            return
        }
        setRequesting(true)
        editProductDetails({ token, product: { ...productFormData, id } }).then((res) => {
            success(res)
            dispatch(editProduct({ ...productFormData, id }))
            requesting(false)
        }).catch(() => {
            setRequesting(false)
        })
    }

    const handleChange = ({ target: { name, value } }) => setProductFormData({ ...productFormData, [name]: value })

    return (
        <div className="flex flex-col bg-white rounded w-[312px] overflow-hidden">
            <div className="bg-gray-50 p-4">
                <p className="text-gray-700 text-center font-semibold">{lang['es']['edit']}</p>
                <p className="text-gray-500 text-center">{name}</p>
            </div>
            <form className="flex flex-col gap-5 p-2" onSubmit={handleEditProduct}>
                <Input type="text" name="name" placeholder={name} value={productFormData.name} onChange={handleChange} />
                <TextArea name="description" onChange={handleChange} placeholder={description} value={productFormData.description} />
                <div className="flex flex-col gap-2">
                    <label className="text-zinc-600">Precio</label>
                    <Input type="number" name="price" placeholder="270.00" value={productFormData.price} onChange={handleChange} />
                </div>
                <Button disabled={requesting}>{requesting ? 'Editando...' : 'Editar'}</Button>
            </form>
        </div>
    )
}

export default EditProductForm
import { useState } from "react"
import { RiArrowDropDownFill, RiArrowDropRightFill } from "react-icons/ri"

const Dropdown = ({ initialState = false, name, children }) => {
    const [open, setOpen] = useState(initialState)

    return (
        <div className="bg-white p-5 flex flex-col gap-5 rounded">
            <button className="flex gap-2 items-center" onClick={() => setOpen(!open)}>
                {name} {open ? <RiArrowDropDownFill /> : <RiArrowDropRightFill />}
            </button>
            {
                open &&
                children
            }
        </div>
    )
}

export default Dropdown
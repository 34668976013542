import { useState } from "react"
import toast from "react-hot-toast"
import { createSection } from "../../services/sections"
import Button from "../button"
import Input from "../input"

const FormCreateSection = ({ token, shopId }) => {
    const [form, setFormData] = useState({
        name: ''
    })

    const handleSubmit = event => {
        event.preventDefault()

        createSection({ token, shopId, sectionName: form.name })
            .then(res => {
                toast.success(res.data.message)
                setFormData({ name: '' })
            }).catch(({ response: res }) => {
                toast.error(res.data.message)
            })
    }

    return (
        <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
            <Input placeholder="Nombre de la sección" onChange={({ target }) => setFormData({ ...form, name: target.value })} value={form.name} />
            <Button>Crear</Button>
        </form>
    )
}

export default FormCreateSection
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import Button from "../../../../components/button"
import Card from "../../../../components/card"
import Input from "../../../../components/input"
import Section from "../../../../components/section"
import { getUser } from "../../../../services/users"
import { setData } from "../../../../features/userSlice"
import { updateAddress } from "../../../../services/addresses"

const UpdateAddress = () => {
    const { id } = useParams()
    const token = useSelector(state => state.token)
    const [address, setAddress] = useState({
        name: '',
        address: '',
        intersection: ''
    })
    const [requesting, setRequesting] = useState(false)
    const dispatch = useDispatch()

    const handleChange = ({ target }) => setAddress({ ...address, [target.name]: target.value })

    const handleSubmit = (event) => {
        event.preventDefault()

        setRequesting(true)
        updateAddress(token, id, address).then(res => {
            setRequesting(false)
            toast.success(res.data.message)
        }).catch(({ response: res }) => {
            setRequesting(false)
            toast.error(res.data.message)
        })
    }

    useEffect(() => {

        getUser(token).then(user => {
            dispatch(setData(user.data.payload))
            const [addressData] = user.data.payload.addresses.filter(address => address.id === parseInt(id))

            setAddress({
                name: addressData.name,
                address: addressData.address,
                intersection: addressData.intersection
            })
        })

    }, [id, token, dispatch])


    return (
        <Section>
            <Card>
                <h1 className="text-lg text-zinc-600">Editar "{address.name}"</h1>
                <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
                    <div className="flex flex-col gap-2">
                        <label>Nombre <span className="text-xs">*Obligatorio</span></label>
                        <Input name="name" type="text" placeholder="Casa" value={address.name} onChange={handleChange} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Dirección <span className="text-xs">*Obligatorio</span></label>
                        <Input name="address" type="address" placeholder="Sargento Cabral 355" value={address.address} onChange={handleChange} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label>Intersección</label>
                        <Input name="intersection" type="text" placeholder="Bv belgrano" value={address.intersection} onChange={handleChange} />
                    </div>
                    <Button spinner={requesting} disabled={requesting}>Editar</Button>
                    <Link className="text-zinc-600 font-semibold text-center" to="/user/addresses">Volver</Link>
                </form>
            </Card>
        </Section>
    )
}

export default UpdateAddress
import axios from "axios";
import { API_URL } from "../config";

export const getUser = (token) => axios.get(`${API_URL}/users`, {
    headers: {
        authorization: `Bearer ${token}`,
    }
})

export const getUserShop = ({ token }) => axios.get(`${API_URL}/users/shop`, {
    headers: {
        authorization: `Bearer ${token}`,
    }
})

export const getUserOrders = (token) => axios.get(`${API_URL}/users/orders`, {
    headers: {
        authorization: `Bearer ${token}`,
    }
})

export const createUser = (data) => axios.post(`${API_URL}/users`, data)

export const updateUserData = (token, data) => axios.put(`${API_URL}/users`, data, {
    headers: {
        authorization: 'Bearer ' + token
    }
})

export const updateUserNumberPhone = (token, data) => axios.put(`${API_URL}/numberphones`, data, {
    headers: {
        authorization: 'Bearer ' + token
    }
})


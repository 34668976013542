import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Card from "../../../../components/card"
import Dropdown from "../../../../components/dropdown"
import FormCreateProduct from "../../../../components/form-create-product"
import FormCreateSection from "../../../../components/form-create-section"
import ShopSection from "../../../../components/shop-section"
import { setShopData } from "../../../../features/shopSlice"
import { setData } from "../../../../features/userSlice"
import { getShop, getShopSections } from "../../../../services/shops"
import { getUser } from "../../../../services/users"

const ShopSections = () => {
    const { id: shopId } = useParams()
    const [loading, setLoading] = useState(true)

    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const shop = useSelector(state => state.shop)

    const dispatch = useDispatch()

    useEffect(() => {
        Promise.all([
            getUser(token),
            getShop(token, shopId),
            getShopSections({ token, shopId })
        ]).then(([user, shop, sections]) => {
            dispatch(setData(user.data.payload))
            dispatch(setShopData(shop.data.payload))
            setLoading(false)
        })
    }, [dispatch, token, shopId])

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <section className="flex flex-col gap-5">
            {
                user.id === shop.userId &&

                <>
                    <Dropdown name="Nueva sección">
                        <FormCreateSection token={token} shopId={shopId} />
                    </Dropdown>
                    <Dropdown name="Nuevo producto">
                        <FormCreateProduct token={token} shopId={shopId} shopSections={shop.shopSections} />
                    </Dropdown>
                </>
            }
            {
                shop.shopSections.length > 0 ?
                    shop.shopSections.map(section => (
                        <ShopSection key={section.id} section={section} initialState={true} />
                    ))
                    : <Card>No hay secciones creadas.</Card>
            }
        </section>
    )
}

export default ShopSections
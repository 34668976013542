import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { getProductCategories } from "../../services/categories"
import { createProduct } from "../../services/products"
import Button from "../button"
import Category from "../category"
import Input from "../input"
import Selectable from "../selectable"
import TextArea from "../textarea"

const FormCreateProduct = ({ token, shopId, shopSections }) => {
    const initialState = {
        shopId,
        name: '',
        description: '',
        categoryId: null,
        sectionId: null,
        price: 0.00
    }
    const [productCategories, setProductCategories] = useState([])
    const [form, setFormData] = useState(initialState)
    const [requesting, setRequesting] = useState(false)

    const handleChange = ({ target }) => setFormData({ ...form, [target.name]: target.value })
    const selectCategory = categoryId => setFormData({ ...form, categoryId: categoryId })
    const selectSection = sectionId => setFormData({ ...form, sectionId: sectionId })

    const handleSubmit = (event) => {
        event.preventDefault()
        createProduct({ token, product: form })
            .then(res => {
                toast.success(res.data.message)
                setFormData(initialState)
            })
            .catch(({ response: res }) => toast.error(res.data.message))
    }

    useEffect(() => {
        getProductCategories().then(categories => {
            setProductCategories(categories.data.payload)
        })
    }, [])

    return (
        <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
                <label className="text-zinc-600">Nombre <span className="text-xs">*Obligatorio</span></label>
                <Input type="text" name="name" placeholder="Manaos 1L" value={form.name} onChange={handleChange} />
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-zinc-600">Descripción</label>
                <TextArea type="text" name="description" value={form.description} onChange={handleChange}></TextArea>
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-zinc-600">Categoría <span className="text-xs">*Obligatorio</span></label>
                <div className="flex flex-col gap-5 max-h-[350px] overflow-y-auto">
                    {productCategories.length > 0 ?
                        productCategories.map(category => (
                            <Category
                                key={category.id}
                                category={category}
                                selected={form.categoryId === category.id}
                                onClick={() => selectCategory(category.id)}
                            />
                        ))
                        :
                        <p>No hay categorias creadas.</p>
                    }
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <label className="text-zinc-600">Sección <span className="text-xs">*Obligatorio</span></label>
                {shopSections.length > 0 ?
                    shopSections.map(section => (
                        <Selectable
                            key={section.id}
                            selected={form.sectionId === section.id}
                            onClick={() => selectSection(section.id)}
                        >
                            <p>{section.name}</p>
                        </Selectable>
                    ))
                    :
                    <p>No hay categorias creadas.</p>
                }
            </div>

            <div className="flex flex-col gap-2">
                <label className="text-zinc-600">Precio <span className="text-xs">*Obligatorio</span></label>
                <Input type="number" name="price" placeholder="270.00" value={form.price} onChange={handleChange} />
            </div>
            <Button spinner={requesting} disabled={requesting}>Crear</Button>
        </form>
    )
}

export default FormCreateProduct
import { useDispatch, useSelector } from "react-redux"
import { addProductToShopOrder, decrementProductQuantity, incrementProductQuantity, removeProductToShopOrder, toggleSoldOutProduct } from "../../features/shopSlice"
import Input from "../input"
import { RiCheckboxBlankCircleFill, RiMore2Fill } from 'react-icons/ri'
import { useState } from "react"
import Modal from "../Modal"
import EditProductForm from "./EditProductForm"
import toast from "react-hot-toast"
import lang from "../../lang"
import { changeSoldOutStatus } from "../../services/products"
import DeleteProduct from "./DeleteProduct"

const Product = (props) => {
    const { product: { id, name, description, price, soldOut } } = props
    const { token, shop, user } = useSelector(state => state)
    const dispatch = useDispatch()

    const [productMenuOptions, setProductMenuOptions] = useState(false)
    const [editProduct, setEditProduct] = useState(false)
    const [deleteProduct, setDeleteProduct] = useState(false)

    const selectEditOption = () => {
        setProductMenuOptions(false)
        setEditProduct(true)
    }

    const selectDeleteOption = () => {
        setProductMenuOptions(false)
        setDeleteProduct(true)
    }

    const handleChangeSoldOutStatus = () => {
        changeSoldOutStatus({ token, productId: id, soldOut: !soldOut })
        dispatch(toggleSoldOutProduct({ id }))
    }

    const addToOrder = () => {
        dispatch(addProductToShopOrder({
            quantity: 1,
            product: props.product
        }))
    }

    const incrementQuantity = () => {
        dispatch(incrementProductQuantity({ productId: id }))
    }
    const decrementQuantity = () => {
        if (shop.order[id].quantity === 1) {
            dispatch(removeProductToShopOrder({ productId: id }))
        } else {
            dispatch(decrementProductQuantity({ productId: id }))
        }
    }

    return (
        <div className="flex flex-col gap-1 min-w-[60%] p-5 bg-white border border-gray-100 rounded overflow-hidden">

            <Modal modalOpen={productMenuOptions} set={setProductMenuOptions}>
                <div className="flex flex-col bg-white rounded w-[312px] overflow-hidden">
                    <div className="bg-gray-50 p-4">
                        <p className="text-gray-700 text-center font-semibold">Opciones</p>
                        <p className="text-gray-500 text-center">{name}</p>
                    </div>
                    <button
                        className="px-5 py-3 uppercase text-center text-sm hover:bg-gray-100 text-gray-500"
                        onClick={selectEditOption}
                    >{lang['es']['edit']}</button>
                    {/* <button className="px-5 py-3 uppercase text-center text-sm hover:bg-gray-100 text-gray-500">{lang['es']['change_section']}</button> */}
                    <button
                        className="px-5 py-3 uppercase text-center text-sm hover:bg-gray-100 text-gray-500"
                        onClick={handleChangeSoldOutStatus}
                    >
                        {soldOut ? lang['es']['mark_as_available'] : lang['es']['mark_as_sold_out']}
                    </button>
                    <button
                        className="px-5 py-3 uppercase text-center text-sm hover:bg-gray-100 text-red-500"
                        onClick={selectDeleteOption}
                    >
                        {lang['es']['delete']}
                    </button>
                </div>
            </Modal>

            {/* Modal editar producto */}

            <Modal modalOpen={editProduct} set={setEditProduct}>
                <EditProductForm
                    noActionCallback={() => setEditProduct(false)}
                    success={() => {
                        toast.success('Producto editado')
                        setEditProduct(false)
                    }}
                    product={props.product} />
            </Modal>

            {/* Modal eliminar producto  */}

            <Modal modalOpen={deleteProduct} set={setDeleteProduct}>
                <DeleteProduct product={props.product} />
            </Modal>

            <div className="flex justify-between">
                <p className="flex gap-1 items-center text-zinc-700 text-sm font-semibold">
                    {name} {soldOut && <span className="flex gap-1 items-center text-red-500 uppercase"><RiCheckboxBlankCircleFill className="text-zinc-500 text-[5px]" /> agotado</span>}
                </p>
                {user.id === shop.userId && <button className="text-gray-500" onClick={() => setProductMenuOptions(true)}><RiMore2Fill /></button>}
            </div>

            <p className="text-green-500">{price}{lang['currency']['abbreviation']}</p>
            <p className="text-zinc-600 text-sm">{description}</p>

        </div>
    )
}

export default Product
const lang = {
    currency: {
        'sign': '$',
        'abbreviation': 'LPS',
        'name': 'Lempiras'
    },
    es: {
        'client': 'Cliente',
        'products': 'Productos',
        'payment_method': 'Método de pago',
        'credit_card': 'Tarjeta de crédito',
        'cash': 'Efectivo',
        'total': 'Total',
        'payment_state': 'Estado del pago',
        'paid': 'Pagado',
        'not_paid': 'No pagado',
        'more_details': 'Más detalles',
        'mark_as_ready': 'Marcar como listo',
        'prepare_again': 'Volver a preparar orden',
        'mark_as_deliveryman_assigned': 'Marcar como repartidor asignado',
        'unassign_deliveryman': 'Desasignar repartidor',
        'mark_as_delivered': 'Marcar como entregado',
        'edit': 'Editar',
        'change_section': 'Cambiar sección',
        'mark_as_sold_out': 'Marcar como agotado',
        'mark_as_available': 'Marcar como disponible',
        'delete': 'Eliminar',
        'deleting': 'Eliminando...',
        'quantity': 'Cantidad'
    }
}

export default lang